<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              @sentReload="sentReload"
              v-bind:heading="heading"
              v-bind:enableAction="selectedItems.length > 0"
              v-bind:selectedItems="selectedItems"
              v-on:revokeSelected="revokeSelected"
            />
            <header-right
              slot="right-toolbar"
              @sentReload="sentReload"
              :enableAction="selectedItems.length > 0"
            />
            <p
              v-if="
                (!mySentList || mySentList.length <= 0) &&
                !axiosLoading &&
                !networkError
              "
              class="align-center center-align"
            >
              {{ $t("text.sent-empty") }}
            </p>

            <v-list
              :data-intro="$t('introjs.this-is-sent-list')"
              two-line
              class="pa-1"
              v-if="!networkError && !axiosLoading && mySentList"
            >
              <template v-for="(item, index) in mySentList">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="$t(`${'label.' + item.header}`)"
                >
                </v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.id_hash"
                  @click.self="showSentDetail(item.id_hash, item.creator_nik)"
                  v-ripple="{ class: `primary--text` }"
                >
                  <v-tooltip bottom v-if="parseInt(item.secretary_flag) === 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        
                        class="att-icon mr-2"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconSecretary }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.copy for secretary") }}</span>
                  </v-tooltip>
                  <label class="mr-2 check--blank" v-else></label>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-on="on"
                        v-bind="attrs"
                        class="d-none d-sm-block mr-2"
                        @click="showSentDetail(item.id_hash, item.creator_nik)"
                      >
                        <v-img :src="item.avatar_path"></v-img>
                      </v-list-item-avatar>
                    </template>
                    <span>{{
                      `${$t("text.signed-by")} : ${item.avatar_label}`
                    }}</span>
                  </v-tooltip>

                  <v-list-item-content
                    @click="showSentDetail(item.id_hash, item.creator_nik)"
                  >
                    <v-list-item-title>
                      <span
                        :data-intro="$t('introjs.this-is-sender-name')"
                        class="sub-headline"
                      >
                        {{ item.title }}
                      </span>
                      <div
                        class="d-inline-flex align-self-center align-center align--vertical-center"
                        :data-intro="$t('introjs.badge-is-tag-for-memo-status')"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="parseInt(item.has_attachments) == 1"
                              class="att-icon mx-1"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ iconAttachment }}
                            </v-icon>
                          </template>
                          <span>{{ $t("tooltip.attachment-exist") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.parent_memo_id_hash"
                              class="att-icon mx-1"
                              color="warning"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ iconReply }}
                            </v-icon>
                          </template>
                          <span>{{ $t("tooltip.replied-memo") }}</span>
                        </v-tooltip>

                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="isSecret(item.tag)"
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ iconSecret }}
                              </v-icon>
                            </template>
                            <span>{{ $t("tooltip.secret") }}</span>
                          </v-tooltip>
                        </div>

                        <a
                          v-if="
                            $vuetify.breakpoint.smAndDown && isSecret(item.tag)
                          "
                          href="javascript:;"
                          class="label theme-bg3 text-white f-12"
                          >{{ $t("badge.confidential") }}</a
                        >
                        <a
                          v-if="
                            $vuetify.breakpoint.smAndDown && isUrgent(item.tag)
                          "
                          href="javascript:void(0)"
                          class="label theme-bg3 text-white f-12"
                          >{{ $t("badge.urgent") }}</a
                        >

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'bom'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.directors-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.directors-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'masuk'"
                              href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12"
                              >{{ $t("badge.incoming-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.incoming-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'eksternal'"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.external-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.external-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="isUrgent(item.tag)"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.urgent") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.urgent") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe == 'izin_prinsip'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.permit-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.permit-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="parseInt(item.has_delegated) == 1"
                              href="javascript:void(0)"
                              class="label theme-bg text-white f-12"
                              >{{ $t("badge.has-delegated") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.has-delegated") }}</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :data-intro="$t('introjs.this-is-memo-number-and-title')"
                      class="d-none d-sm-block"
                    >
                      {{
                        $t("label.number") + `: ${cutJudul(item.subtitle, 150)}`
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-block d-sm-none">
                      {{ cutJudul(item.subtitle, 150) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-if="!$vuetify.breakpoint.smAndDown"
                        class="text-right"
                        v-on="on"
                        v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showSentDetail(item.id_hash, item.creator_nik)"
                      >
                        {{ $date(item.data_time).format("DD MMM YYYY") }}
                        <br />
                        {{ $date(item.data_time).format("HH:mm") }} WIB
                      </span>
                      <span
                        class="text-right"
                        v-else
                        v-on="on"
                        v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showSentDetail(item.id_hash, item.creator_nik)"
                      >
                        {{ $date(item.data_time).format("DD MMM YYYY") }}
                        <br />
                        {{ $date(item.data_time).format("HH:mm") }} WIB
                      </span>
                    </template>
                    <span>{{
                      `${$t("tooltip.sending-at")} ${timeAgo(item.data_time)}`
                    }}</span>
                  </v-tooltip>
                </v-list-item>
              </template>
            </v-list>
            <v-card-text v-if="networkError || axiosLoading || !mySentList">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
              >
              </v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-bind:submitOptions="submitOptions"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
    ></insert-comment>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { isIntroActive, rowPerPage } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";
import InsertComment from "@/components/commonComponents/InsertComment.vue";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiBackupRestore,
  mdiReplyCircle,
  mdiFileStarOutline,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    InsertComment,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconSecretary: mdiFileStarOutline,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconRevoke: mdiBackupRestore,
    iconReply: mdiReplyCircle,
    selectedItems: [],
    isCheckedAll: false,
    showCommentDialog: false,
    submitOptions: undefined,
    comment: undefined,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("sent", [
      "sentList",
      "axiosLoading",
      "filterParams",
      "selectAllSent",
    ]),
    currUserNik() {
      if (!this.currentUser.nik) return null;
      return this.currentUser.nik;
    },
    mySentList() {
      if (!this.sentList) return [];
      return this.sentList.data;
    },
    memoIds() {
      if (!this.sentList) return [];
      return this.sentList.data.map(({ id_hash }) => id_hash);
    },
    heading() {
      const isX = this.$route.name === "sentx" ? true : false;
      if (isX) return this.$t("pagetitle.memo-sentx");
      return this.$t("pagetitle.memo-sent");
    },
  },
  beforeDestroy() {
    this.setFilterParams({
      sortBy: "data_time",
      sortByDesc: "desc",
      perPage: rowPerPage,
      currentPage: 1,
      only: "",
      filter: "",
      from: 1,
      to: 50,
      lastPage: 1,
    });
  },
  created() {
    //  const isX = this.$route.name === "sentx" ? true : false
    this.sentReload();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    "$route.name": {
      handler: function (name) {
        this.sentReload(name);
      },
      deep: true,
      immediate: true,
    },
    selectAllSent(val) {
      if (this.selectedItems == this.memoIds) return;

      this.setSelectAll(false);
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      if (val === true) {
        this.selectedItems = this.addToArray(this.selectedItems, this.memoIds);
      }
      this.updateCheckAll();
    },
  },
  methods: {
    ...mapActions("sent", [
      "loadSent",
      "loadSentX",
      "emptySent",
      "setFilterParams",
      "setAxiosLoading",
      "setSelectAll",
    ]),
    showSentDetail(id, creatorNik) {
      const isX = this.$route.name === "sentx" ? true : false;
      let detailPage = "sent.detailmar";

      switch (isX) {
        case true:
          if (this.currUserNik === creatorNik) {
            detailPage = "sentx.detailmac";
          } else {
            detailPage = "sentx.detailmar";
          }
          break;
        default:
          if (this.currUserNik === creatorNik) {
            detailPage = "sent.detailmac";
          } else {
            detailPage = "sent.detailmar";
          }
          break;
      }

      setTimeout(() => {
        //beri jeda untuk animasi ripple
        return this.$router.push({
          name: detailPage,
          params: { id: id },
        });
      }, 300);
    },
    sentReload() {
      const sentX = this.$route.name === "sentx" ? true : false;
      // this.setFilterParams(this.filterParams);
      this.setSelectAll(false);
      //remove dulu selected item
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      this.emptySent();
      if (sentX) {
        this.loadSentX(this.filterParams);
      } else {
        this.loadSent(this.filterParams);
      }

      // setTimeout(() => {
      //   this.overlay = false;
      // }, 3000);
    },
    updateCheckAll() {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 0
      ) {
        this.setSelectAll(true);
      } else {
        this.setSelectAll(false);
      }
    },
    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    isUrgent(val) {
      let tagList = ["urgent"];
      return tagList.includes(val);
    },
    isSecret(val) {
      let tagList = ["secret"];
      return tagList.includes(val);
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    commentFilled(val) {
      if (!val) return;
      this.comment = val;
      // this.terminateSelected();
    },
    revokeSelected() {
      if (!this.selectedItems) return;
      if (this.selectedItems.length > 1) {
        return this.$dialog.error({
          text: this.$t("msgbox.cannot-revoke-more-than-one-draft-at-a-time"),
          title: this.$t("msgbox.revoke-sent-memo"),
          persistent: true,
        });
      }
      if (!this.comment) {
        this.submitOptions = {
          btnText: "button.revoke",
          btnIcon: this.iconRevoke,
          commentDesc: this.$t("text.please-fill-revoke-comment-or-reason"),
        };
        return (this.showCommentDialog = true);
      }
    },
  },
};
</script>
